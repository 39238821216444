<p
  *ngIf="textCitation"
  class="chat-message-citation"
  [matTooltip]="citationTitle"
>
  {{ citationSourceUrl || citationTitle }}
</p>

<a
  *ngIf="!textCitation"
  class="chat-message-citation"
  rel="noopener"
  [href]="citationSourceUrl"
  target="_blank"
  [matTooltip]="citationSourceUrl"
>
  {{ citationTitle || citationSourceUrl }}
</a>
