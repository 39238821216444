import { AfterViewInit, Component, Input } from '@angular/core'
import { CWMarkdownService } from '@cwan-gpt-ui/services'
@Component({
  selector: 'cw-markdown',
  templateUrl: './cw-markdown.component.html',
  styleUrls: [`./cw-markdown.component.scss`],
})
export class CWMarkdownComponent implements AfterViewInit {
  rawHtml = ''
  @Input() applyCustomStyle = true

  @Input()
  set markdownText(value: string | undefined) {
    this.rawHtml = this.cwMarkdownService.convertMarkdownToHtml(value ?? '')
  }

  constructor(private cwMarkdownService: CWMarkdownService) {}

  ngAfterViewInit() {
    this.cwMarkdownService.highlightAll()
  }
}
